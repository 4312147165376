import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { InfoIcon, List } from '../components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`There are a few things you should know before you start using these services:`}<br /></p>
    <InfoIcon mdxType="InfoIcon" /> User account operations can only be done through the SmartVault API if you are authenticated using
autonomous authentication.
    <br />
    <strong style={{
      "marginLeft": "2rem"
    }}>You cannot use pin-based authentication for these calls.</strong>
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" /> The "Authorization" header used for <a href="/api/user-accounts/new-account">
	creating new accounts
    </a> and <a href="/api/user-account/stat-user">getting account information</a> is based on the client token, not the delegation
token like most other API calls.
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" /> For the <a href="/api/user-accounts/managing-user-properties">
	user properties
    </a> and <a href="/api/user-accounts/managing-account-properties">account properties</a> management you'll need to use
the delegation token as usual.
    <br />
    <br />
    <p>{`Read more about authentication methods `}<a parentName="p" {...{
        "href": "/api/before-you-begin#authentication-what-kind-of-application-are-you-building"
      }}>{`here`}</a>{` and how to retrieve each token `}<a parentName="p" {...{
        "href": "/api/authorization/introduction"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      